import React, { lazy, Suspense } from "react";
import ReactDom from "react-dom";
import "@amzn/awsui-global-styles/polaris.css";
import { Spinner } from "./shared/components/spinner";
import { ErrorBoundary } from "./shared/components/error";

/** Check if should enable debug mode */
const params = new URLSearchParams(window.location.search);
window.CYCLONE_DEBUG = process.env.NODE_ENV === "development" || params.has("debug");

const AppShell = lazy(() => import("./modules/core/components/app-shell/app-shell"));

const Root = () => (
  <ErrorBoundary>
    <Suspense fallback={<Spinner />}>
      <AppShell />
    </Suspense>
  </ErrorBoundary>
);

ReactDom.render(<Root />, document.getElementById("app"));
