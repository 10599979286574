import React from "react";
import PolarisSpinner from "@amzn/awsui-components-react/polaris/spinner";

export const Spinner = () => (
  <div style={{ height: "100vh", display: "grid" }}>
    <div style={{ margin: "auto" }}>
      <PolarisSpinner size="large"></PolarisSpinner>
    </div>
  </div>
);
